import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { startLoading, endLoading } from "@/utils/function";
import API from '@/api/teacher/other/workStatPage.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    // 页面名称
    name: "colleague",
    data()
    {
        return{
            activeName: 'first',
            virtualDate:[],
            courseList:[],
            course_id:'',
            projectDataList:[],
            openDataList:[],
            openDate:[],
            openDataList:[],
            courseTotal:0,
            openTotal:0,

        }
    },
    created()
    {
        let self = this;
        self.getData();
    },
    methods:
    {
        getData()
        {
            try 
            {
                let self=this;
                // 请求参数
                let data = {
                    search : self.searchContent
                };
                // 开启进度条
                nprogressStart();
                self.$Loading.show();
                // 课程项目工作量统计
                API.getCourseList(data)
                    .then((result)=>{
                        // 关闭进度条
                        nprogressClose();
                        self.$Loading.hide();
                        self.courseList = result.list;
                    })                
            } 
            catch (error) 
            {
                Message.error('系统错误');
            }
        },
        handleClick(tab, event) 
        {
            let self = this;
            console.log(tab.name);
            self.activeName = tab.name;
            if(self.activeName == 'first')
            {
                self.getAllProjectByCourseidWorkStat();
            }
            else
            {
                // self.getOpenDateWorkStat();
            }
        },
        getAllProjectByCourseidWorkStat()
        {
            try 
            {
                let self=this;
                let data = {
                    start:self.virtualDate[0],
                    end:self.virtualDate[1],
                    courseid:self.course_id,
                };
                nprogressStart();
                self.$Loading.show();
                API.getAllProjectByCourseidWorkStat(data)
                    .then((result)=>{
                        nprogressClose();
                        self.$Loading.hide();
                        self.projectDataList = result.data;
                        self.projectDataList.forEach( (element,key) => {
                            element.info.forEach((item,k) => {
                                self.projectDataList[key].info[k].hoursPerson = parseFloat(item.hours) * parseFloat(item.zrsjs);
                                self.courseTotal += parseFloat(self.projectDataList[key].info[k].hoursPerson);
                            })                        
                        });
                    })                
            } 
            catch (error) 
            {
                Message.error('系统错误');
            }
        },
        getOpenDateWorkStat()
        {
            try 
            {
                let self = this;
                // 请求参数
                let data = {
                    // 开始时间
                    start:self.openDate[0],
                    // 结束时间
                    end:self.openDate[1],
                };
                nprogressStart();
                self.$Loading.show();
                // 日程工作量统计
                API.getOpenDateWorkStat(data)
                    .then((result)=>{
                        nprogressClose();
                        self.openDataList = result.data;
                        self.openTotal = result.total;
                        self.$Loading.hide();
                    })                
            } 
            catch (error) 
            {
                Message.error('系统错误');
            }
        },
    }
};
